import { useEffect, useState } from "react";
import {
  FetchSingleCourse,
  getAllUserCourses,
  getMyCourse,
  getProgress,
  getProgressById,
  GetUserDetails,
  GetStudentByBatchId,
  GetCourseByBatchId,
} from "Axios/apis";
import { useDispatch, useSelector } from "react-redux";
import { Show } from "components/Show";
import arrow from "../../icons/arrow.png";
import uiUx from "../../icons/placeholder2.png";
import clock from "../../icons/clock.svg";
import star from "../../icons/star.svg";
import stardark from "../../icons/stardark.svg";
import progressAnalysis from "icons/progress-analysis.png";
import video from "../../icons/courseplaceholder.png";
import carrer1 from "../../icons/carrer (1).png";
import carrer2 from "../../icons/carrer (2).png";
import carrer3 from "../../icons/carrer (3).png";
import carrer4 from "../../icons/carrer (4).png";
import gradute from "../../icons/gra.svg";
import prettyName from "pretty-name";
import { Accordion } from "components/Section/CourseItem";
import { courseId, SingleCourse } from "redux/coures/courseAction";
import ProgressTracker from "components/ProgressTracker";
import NotEnrolled from "components/NotEnrolled";
import { AccordionLiveSession } from "components/Section/LiveSessionItem";
import "./dashboard.css";
import { motion } from "framer-motion";
import HeaderTop from "components/HeaderTop";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "components/Spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Dashboard = (props) => {
  const [courses, setCourses] = useState([]);
  const location = useLocation();
  const [cID, setCourseID] = useState("");
  const [singleCourse, setSingleCourse] = useState([]);
  const { loader } = useSelector((state) => state.course);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const user2 = localStorage.getItem("user2");
  const [loadspinner, setloading] = useState(true);
  const [progressbar, setProgressBar] = useState();
  const [totalprogress, setTotalProgress] = useState();
  const [progresscircle, setProgressCircle] = useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const lastCourseProgreess =
    (progresscircle.completedChapters / progresscircle.totalChapters) * 100 ||
    0.0;
  const role = localStorage.getItem("role");
  const interviewProRole = localStorage.getItem("InterviewProRole");

  useEffect(() => {
    const fetchUserCourses = async () => {
      setloading(true);
      console.log("Loading started");
      try {
        // Fetch user details from local storage
        const storedUser = await GetUserDetails(userId);
        if (!storedUser) {
          console.error("No user details found in local storage");
          setloading(false);
          return;
        }
        const user = storedUser.data.result[0];
        console.log("User details fetched from local storage:", user);
        // const userResponse = await GetUserDetails(user._id); // Assuming you have a function to get user details
        // console.log("User details fetched:", userResponse);

        const teams = user?.teams || [];
        console.log("User teams:", teams);

        // Fetch batches (teams) details and extract courses
        console.log("Fetching batches details...");
        const batchesPromises = teams.map((teamId) =>
          GetCourseByBatchId(teamId)
        ); // Assuming you have a function to get batch details
        const batchesResponses = await Promise.all(batchesPromises);
        console.log("Batches details fetched:", batchesResponses);

        const coursesFromBatches =
          batchesResponses.flatMap(
            (response) => response?.data?.data?.course
          ) || [];
        console.log("Courses extracted from batches:", coursesFromBatches);

        const filteredCourses = [
          ...new Map(
            coursesFromBatches.map((course) => [course._id, course])
          ).values(),
        ];
        console.log("Filtered courses:", filteredCourses);

        // Fetch full course details using courseID
        console.log("Fetching full course details...");
        const courseDetailsPromises = filteredCourses.map(
          (course) => FetchSingleCourse(course._id, userId) // Assuming you have a function to get course details
        );
        const courseDetailsResponses = await Promise.all(courseDetailsPromises);
        console.log("Full course details fetched:", courseDetailsResponses);

        // Extract the course data from the responses
        const fullCourseDetails = courseDetailsResponses.map(
          (response) => response.data.courses
        );
        console.log("Extracted full course details:", fullCourseDetails);

        setCourses(fullCourseDetails);

        const accessCode = courseDetailsResponses.map((item) => {
          return { code: item?.accessCode, courseID: item?.courseID };
        });
        console.log("Access codes:", accessCode);

        localStorage.setItem("accessCode", JSON.stringify(accessCode));

        console.log("Fetching progress...");
        const result = await getProgress(
          {
            userId: user._id,
          },
          token
        );
        console.log("Progress fetched:", result);
        setProgressBar(result?.data?.result);

        const totalChapter1 = fullCourseDetails?.map((item) =>
          item?.section?.reduce((total, section) => {
            return (total += section.chapter.length);
          }, 0)
        );
        console.log("Total chapters per course:", totalChapter1);

        const totalChaptersCourse = totalChapter1?.reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
        console.log("Total chapters across all courses:", totalChaptersCourse);

        // Ensure progressBar is defined
        const completedChapters = result?.data?.result?.completedChapters || 0;
        console.log("completedchapter", completedChapters);
        const totalProgress =
          totalChaptersCourse > 0
            ? (completedChapters / totalChaptersCourse) * 100
            : 0;
        console.log("Total progress percentage:", totalProgress);

        // Ensure totalWatchedTime is a valid number
        const totalWatchedTime = result?.data?.result?.totalWatchedTime;
        console.log("Total watch time:", totalWatchedTime);
        const progressTime =
          typeof totalWatchedTime === "number" && totalWatchedTime >= 0
            ? toHoursAndMinutes(totalWatchedTime)
            : "0h 0m";
        console.log("Total live time:", progressTime);
        setTotalLive(progressTime);

        const count2 = fullCourseDetails
          ?.map((item) => {
            return item?.section?.map(
              (item) =>
                item?.chapter?.filter((value) => value?.isCompleted === true)
                  ?.length
            );
          })
          .flat();
        console.log("Completed chapters count per section:", count2);

        setTotalCompletedCount(isNaN(totalProgress) ? 0 : totalProgress);

        const sumofAllChapter = count2
          ?.flat()
          ?.reduce((partialSum, a) => partialSum + a, 0);
        console.log("Sum of all completed chapters:", sumofAllChapter);

        settotalsum(sumofAllChapter);
        setloading(false);
        console.log("Loading finished");
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsOpened(false);
        setloading(false);
      }
    };

    // fetchCourse();
    // fetchProgress();
  }, []);
  const { userData } = useSelector((state) => state.user);
  const [myCourses, setmyCourses] = useState([])
  console.log(userData)

  const fetchMyCourse = async () => {
    try {
      setloading(true);

      const payload = {
        userId: localStorage.getItem("userId")
      }
      const res = await getMyCourse(payload)
      setmyCourses(res.data.courses)
      setloading(false);
    } catch (error) {
      console.log(error)
      setloading(false);
    }
  }
  useEffect(() => {
    fetchMyCourse()
  }, [])


  const { course } = useSelector((state) => state.course);

  const [show, setShow] = useState(false);
  const [progressTracker, setProgressTracker] = useState(false);

  const lastCourse = courses?.filter(
    (item) => item.courseID === cID || item.courseID === course
  );
  // //console.log("lastCourse", lastCourse);
  const courseBuy = courses?.filter((c) => c._id === course) || {};

  const singleCoursePlay = async (id) => {
    setShow(true);
    setSingleCourse(
      courses.filter((item) => item._id === id || item.courseID === id)
    );
  };

  const handleProgressTracker = () => {
    setProgressTracker(true);
  };

  const totalChapters =
    lastCourse.length &&
    lastCourse[0]?.section?.reduce((total, section) => {
      return total + section.chapter.length;
    }, 0);

  const count1 =
    lastCourse.length &&
    lastCourse[0]?.section.map(
      (item) =>
        item.chapter.filter((value) => value.isCompleted === true).length
    );

  const sum =
    lastCourse.length && count1.reduce((partialSum, a) => partialSum + a, 0);
  const [progress, setProgress] = useState(0);
  const [totalLive, setTotalLive] = useState(0);
  const [totalChapter, SettotalChapter] = useState(0);
  const [totalCompletedCount, setTotalCompletedCount] = useState(0);
  const [countotalsum, settotalsum] = useState(0);
  const { courseList } = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (courses.length) {
  //     // const totalLive1 = courses?.map((item) =>
  //     //   setTotalLive((prev) => prev + item.liveSession.length)
  //     // );
  //     const totalChapter1 = courses?.map((item) =>
  //       item?.section?.reduce((total, section) => {
  //         return (total += section.chapter.length);
  //       }, 0)
  //     );
  //     const count2 = courses
  //       ?.map((item) => {
  //         return item?.section?.map(
  //           (item) =>
  //             item?.chapter?.filter((value) => value?.isCompleted === true)
  //               ?.length
  //         );
  //       })
  //       .flat();
  //     // //console.log(count2);
  //     // //console.log(courses);
  //     const totalChaptersCourse = totalChapter1?.reduce((acc, curr) => {
  //       return (acc += curr);
  //     }, 0);
  //     // //console.log("Total Chapters", totalChaptersCourse)

  //     const totalProgress =
  //       (progressbar?.completedChapters / totalChaptersCourse) * 100;

  //     const progressTime = toHoursAndMinutes(progressbar?.totalWatchedTime);
  //     setTotalLive(progressTime);
  //     setTotalCompletedCount(isNaN(totalProgress) ? 0 : totalProgress);
  //     const sumofAllChapter = count2
  //       ?.flat()
  //       ?.reduce((partialSum, a) => partialSum + a, 0);
  //     settotalsum(sumofAllChapter);
  //   }
  //   console.log(lastCourse);
  //   const fetchProgress = async () => {
  //     if (lastCourse.length > 0) {
  //       const resProgress = await getProgressById(
  //         {
  //           course_id: lastCourse[0]?.courseID,
  //         },
  //         token
  //       );
  //       // //console.log(resProgress.data.result)
  //       setProgressCircle(resProgress?.data?.result || 0);
  //     }
  //   };
  //   fetchProgress();
  // }, [courses, lastCourse?.length]);

  const [buttonhead, setButtonHeading] = useState("");

  useEffect(() => {
    if (lastCourse.length) {
      const updatedProgress = (sum / totalChapters) * 100;
      setProgress(updatedProgress);
    }
  }, [lastCourse]);

  const progressLine = Math.ceil(progress / 25) || 0;

  const totalChapter2 = courses.map((item) =>
    item.section.reduce((total, section) => {
      return total + section?.chapter?.length;
    }, 0)
  );

  function toHoursAndMinutes(totalSec) {
    return new Date(totalSec * 1000).toISOString().slice(11, 19);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="dashboard">
        {progressTracker ? (
          <div>
            <ProgressTracker></ProgressTracker>
            <NotEnrolled></NotEnrolled>
          </div>
        ) : (
          <>
            <div className="row catalogue">
              <HeaderTop
                togglesidebar={props.togglesidebar}
                name="My Courses"
              />
            </div>
            {loadspinner ? (
              <Spinner />
            ) : (
              <>
                {myCourses?.length !== 0 && (
                  <>
                    <div className="recommended">
                      <div className="mt-4 flex items-center justify-between align-items-center">
                        <strong
                          style={{ fontSize: "25px" }}
                          className="mb-12-res course-tracker-head"
                        >
                          Course Tracker
                        </strong>
                      </div>
                      <div className="row mt-4 mx-2 justify-center-res1 ">
                        <div className="col-xl-3 col-sm-4 mb-12-res">
                          <div className="card2 gradient-1 card-bx">
                            <div className="card-body2 d-flex align-items-center">
                              <div className="me-auto text-white ">
                                <h2 className="text-white fs-30">
                                  {myCourses?.length || "0"}
                                </h2>
                                <span className="fs-18">My Courses</span>
                              </div>
                              <svg
                                width="58"
                                height="58"
                                viewBox="0 0 58 58"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M29.0611 39.4402L13.7104 52.5947C12.9941 53.2089 11.9873 53.3497 11.1271 52.9556C10.2697 52.5614 9.7226 51.7041 9.7226 50.7597C9.7226 50.7597 9.7226 26.8794 9.7226 14.5028C9.7226 9.16424 14.0517 4.83655 19.3904 4.83655H38.7289C44.0704 4.83655 48.3995 9.16424 48.3995 14.5028V50.7597C48.3995 51.7041 47.8495 52.5614 46.9922 52.9556C46.1348 53.3497 45.1252 53.2089 44.4088 52.5947L29.0611 39.4402ZM43.5656 14.5028C43.5656 11.8335 41.3996 9.66841 38.7289 9.66841C33.0207 9.66841 25.1014 9.66841 19.3904 9.66841C16.7196 9.66841 14.5565 11.8335 14.5565 14.5028V45.5056L27.4873 34.4215C28.3926 33.646 29.7266 33.646 30.6319 34.4215L43.5656 45.5056V14.5028Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-3 col-sm-4 mb-12-res">
                          <div className="card2 gradient-1 card-bx">
                            <div className="card-body2 d-flex align-items-center">
                              <div className="me-auto text-white">
                                <h2 className="text-white fs-30">
                                  {totalCompletedCount?.toFixed(2) + "%" || "0"}
                                </h2>
                                <span className="fs-18">My Progress</span>
                              </div>
                              <svg
                                width="58"
                                height="58"
                                viewBox="0 0 58 58"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M36.25 9.66665V7.24998C36.25 5.91598 37.3327 4.83331 38.6667 4.83331C40.0007 4.83331 41.0833 5.91598 41.0833 7.24998V9.66665C46.4242 9.66665 50.75 13.9949 50.75 19.3333V43.5C50.75 48.8384 46.4242 53.1666 41.0833 53.1666C34.1741 53.1666 23.8283 53.1666 16.9167 53.1666C11.5782 53.1666 7.25 48.8384 7.25 43.5V19.3333C7.25 13.9949 11.5782 9.66665 16.9167 9.66665V7.24998C16.9167 5.91598 17.9993 4.83331 19.3333 4.83331C20.6673 4.83331 21.75 5.91598 21.75 7.24998V9.66665H36.25ZM45.9167 29H12.0833V43.5C12.0833 46.168 14.2487 48.3333 16.9167 48.3333H41.0833C43.7537 48.3333 45.9167 46.168 45.9167 43.5V29ZM33.5748 37.8329L36.9822 34.5172C37.9392 33.5868 39.469 33.6086 40.3994 34.5656C41.3298 35.5202 41.3081 37.0523 40.3535 37.9827L35.3848 42.8161C34.4955 43.6788 33.1011 43.732 32.1513 42.9393L29.4302 40.6677C28.4055 39.8146 28.2677 38.2896 29.1232 37.265C29.9763 36.2403 31.5012 36.1026 32.5259 36.9581L33.5748 37.8329ZM41.0833 14.5V16.9166C41.0833 18.2506 40.0007 19.3333 38.6667 19.3333C37.3327 19.3333 36.25 18.2506 36.25 16.9166V14.5H21.75V16.9166C21.75 18.2506 20.6673 19.3333 19.3333 19.3333C17.9993 19.3333 16.9167 18.2506 16.9167 16.9166V14.5C14.2487 14.5 12.0833 16.6629 12.0833 19.3333V24.1666H45.9167V19.3333C45.9167 16.6629 43.7537 14.5 41.0833 14.5Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-3 col-sm-4">
                          <div className="card2 gradient-1 card-bx">
                            <div className="card-body2 d-flex align-items-center">
                              <div className="me-auto text-white">
                                <h2 className="text-white fs-30">
                                  {totalLive || "0"}
                                </h2>
                                <span className="fs-18">My Watch Time</span>
                              </div>
                              <svg
                                width="57"
                                height="46"
                                viewBox="0 0 57 46"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.55512 20.7503L11.4641 17.8435C12.3415 16.9638 12.3415 15.5397 11.4641 14.6601C10.5844 13.7827 9.16031 13.7827 8.28289 14.6601L1.53353 21.4094C0.653858 22.2891 0.653858 23.7132 1.53353 24.5929L8.28289 31.3422C9.16031 32.2197 10.5844 32.2197 11.4641 31.3422C12.3415 30.4626 12.3415 29.0385 11.4641 28.1588L8.55512 25.2498H27.8718C29.1137 25.2498 30.1216 24.2419 30.1216 23C30.1216 21.7604 29.1137 20.7503 27.8718 20.7503H8.55512Z"
                                  fill="white"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5038 31.9992V36.4987C16.5038 41.4708 20.5332 45.4979 25.5029 45.4979H48.0008C52.9728 45.4979 57 41.4708 57 36.4987C57 29.0092 57 16.9931 57 9.50129C57 4.53151 52.9728 0.502136 48.0008 0.502136C41.5687 0.502136 31.9373 0.502136 25.5029 0.502136C20.5332 0.502136 16.5038 4.53151 16.5038 9.50129V14.0009C16.5038 15.2427 17.5117 16.2507 18.7536 16.2507C19.9955 16.2507 21.0034 15.2427 21.0034 14.0009C21.0034 14.0009 21.0034 11.8928 21.0034 9.50129C21.0034 7.01752 23.0192 5.00171 25.5029 5.00171H48.0008C50.4868 5.00171 52.5004 7.01752 52.5004 9.50129V36.4987C52.5004 38.9848 50.4868 40.9983 48.0008 40.9983C41.5687 40.9983 31.9373 40.9983 25.5029 40.9983C23.0192 40.9983 21.0034 38.9848 21.0034 36.4987C21.0034 34.1095 21.0034 31.9992 21.0034 31.9992C21.0034 30.7595 19.9955 29.7494 18.7536 29.7494C17.5117 29.7494 16.5038 30.7595 16.5038 31.9992Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div>
                  <Show condition={!isOpened}>
                    {lastCourse.length > 0 && (
                      <>
                        <div className="mb-8 recommended mt-4">
                          <strong className="mb-8 ml-4 recommended continue-course ">
                            Continue Watching
                          </strong>
                          <div className="row mt-12 mx-4 justify-content-start">
                            {console.log(lastCourse)}
                            {lastCourse?.map((course, index) => (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className="mb-8 cursor-pointer card-border mt-24"
                                onClick={() => {
                                  navigate(`${course?.courseID}`);
                                  // setIsOpened(true);
                                  // setButtonHeading("Resume Course");
                                  // dispatch(courseId(course.courseID));
                                }}
                              >
                                <Link to={course?.courseID}>
                                  <div className="wrap-circles">
                                    <div
                                      className="circle per-25"
                                      style={{
                                        position: "absolute",
                                        top: -80,
                                        left: 160,
                                        backgroundImage: `conic-gradient(#FEA302 ${lastCourseProgreess}%, #FFCDB2 0)`,
                                        zIndex: "9",
                                      }}
                                    >
                                      <div className="inner">
                                        {lastCourseProgreess.toFixed(2) || 0}
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    className="block mx-auto card-image"
                                    onClick={() =>
                                      dispatch(courseId(course._id))
                                    }
                                    src={course.thumbnail || uiUx}
                                    onError={(e) => {
                                      e.target.src =
                                        "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg";
                                    }}
                                    alt=""
                                  />
                                  <div>
                                    <div className="heading-card-div">
                                      <OverlayTrigger
                                        key={
                                          course.name
                                            ? course.name
                                            : "Course Name"
                                        }
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {course.name
                                              ? course.name
                                              : "Course Name"}
                                          </Tooltip>
                                        }
                                      >
                                        <h1 className="training pt-3">
                                          {course.name
                                            ? course.name
                                            : "Course Name"}
                                        </h1>
                                      </OverlayTrigger>
                                    </div>
                                    <div
                                      style={{
                                        color: "#fea302",
                                        marginBottom: "10px",
                                      }}
                                      className="px-2 text-end"
                                    >
                                      <button className="join-session-btn">
                                        Continue
                                      </button>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                    <Show condition={myCourses?.length === 0}>
                      <NotEnrolled></NotEnrolled>
                    </Show>
                    <Show condition={myCourses?.length > 0}>
                      <div className="mt-4 recommended">
                        <strong className="mb-8 ml-4 recommended continue-course">
                          My Courses
                        </strong>

                        <div className="row mt-12 mx-4 justify-content-start">
                          {myCourses?.map((course, index) => (
                            <div
                              key={index}
                              className="mb-8 cursor-pointer card-border mt-24"
                              onClick={() => {
                                setButtonHeading("Play Course");
                                // setIsOpened(true);
                                // setCourseID(course.courseID);
                                // dispatch(courseId(course.courseID));
                              }}
                            >
                              {course?.name === "Resumatic AI" ||
                                course?.name === "Interview Pro" ? (
                                <div>
                                  <img
                                    className="block mx-auto card-image"
                                    src={course.thumbnail || uiUx}
                                    onError={(e) => {
                                      e.target.src =
                                        "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg";
                                    }}
                                    alt=""
                                  />
                                  <div>
                                    <div className="heading-card-div">
                                      <OverlayTrigger
                                        key={
                                          course.name
                                            ? course.name
                                            : "Course Name"
                                        }
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {course.name
                                              ? course.name
                                              : "Course Name"}
                                          </Tooltip>
                                        }
                                      >
                                        <h1 className="training pt-3">
                                          {course.name
                                            ? course.name
                                            : "Course Name"}
                                        </h1>
                                      </OverlayTrigger>
                                    </div>
                                    <div
                                      style={{
                                        color: "#fea302",
                                        marginBottom: "10px",
                                      }}
                                      className="px-2 text-end"
                                    >
                                      <a
                                        href={
                                          course?.name === "Interview Pro"
                                            ? `https://interviewpro.viosa.in/?token=${localStorage.getItem(
                                              "token"
                                            )}&role=${localStorage.getItem(
                                              "role"
                                            )}&userId=${localStorage.getItem(
                                              "userId"
                                            )}&hireProRole=${localStorage.getItem(
                                              "InterviewProRole"
                                            )}`
                                            : `https://resumatic.viosa.in/auth?token=${localStorage.getItem(
                                              "token"
                                            )}&id=${localStorage.getItem(
                                              "userId"
                                            )}`
                                        }
                                        target="_blank"
                                        style={{ padding: "7px 10px", marginBottom: 0 }}
                                        className="join-session-btn"
                                      >
                                        Start Course
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <Link to={course?._id}>
                                  <img
                                    className="block mx-auto card-image"
                                    src={course.thumbnail || uiUx}
                                    onError={(e) => {
                                      e.target.src =
                                        "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg";
                                    }}
                                    alt=""
                                  />
                                  <div>
                                    <div className="heading-card-div">
                                      <OverlayTrigger
                                        key={
                                          course.name
                                            ? course.name
                                            : "Course Name"
                                        }
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {course.name
                                              ? course.name
                                              : "Course Name"}
                                          </Tooltip>
                                        }
                                      >
                                        <h1 className="training pt-3">
                                          {course.name
                                            ? course.name
                                            : "Course Name"}
                                        </h1>
                                      </OverlayTrigger>
                                    </div>
                                    <div
                                      style={{
                                        color: "#fea302",
                                        marginBottom: "10px",
                                      }}
                                      className="px-2 text-end"
                                    >
                                      <button className="join-session-btn">
                                        Start Course
                                      </button>
                                    </div>
                                  </div>
                                </Link>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Show>
                    {!userData?.courseList?.length === 0 && (
                      <div className="mt-10 mb-12">
                        <strong className="mb-8 ml-4 continue-course">
                          Progress Tracker
                        </strong>

                        <div
                          style={{ width: "300px", height: "270px" }}
                          onClick={handleProgressTracker}
                        >
                          <figure>
                            <img src={progressAnalysis} alt="" />
                          </figure>
                        </div>
                      </div>
                    )}
                  </Show>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </motion.div>
  );
};

export default Dashboard;
