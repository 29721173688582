import React, { useState, useEffect } from "react";
import "./AssignmentQuiz.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import QuizQuestionCard from "./QuizQuestionCard";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const AssignmentQuiz = ({ timeLeft, setShowResults }) => {
  const navigate = useNavigate();
  const { courseLink } = useSelector((state) => state.course);
  // const questionList = courseLink?.link6?.length;
  const questionList = courseLink?.link6 || [];
  const totalQuestions = questionList.length;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);

  const handleClick = () => {
    navigate("/assignment/submit");
  };
  // console.log("questionList", questionList);
  console.log("total question", totalQuestions);
  console.log("courseLink", courseLink);
  useEffect(() => {
    if (timeLeft <= 0) {
      setShowResults(true);
    }
  }, [timeLeft, setShowResults]);
  // const questions = [
  //   {
  //     question: "This is question -1",
  //     options: ["Option-1", "Option-2", "Option-3", "Option-4"],
  //   },
  //   {
  //     question: "This is question 2",
  //     options: ["Option-1", "Option-2", "Option-3", "Option-4"],
  //   },
  // ];
  // Calculate progress based on the current question index
  // Function to handle when a question is answered
  const handleNextQuestion = () => {
    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      { questionId: questionList[currentQuestionIndex].id, selectedOption },
    ]);
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
    } else {
      setShowResults(true);
      navigate("/assignment/submit", { state: { userAnswers, questionList } });
    }
  };

  const handleAnswer = (optionIndex) => {
    setSelectedOption(optionIndex);
  };
  // Calculate progress based on the current question index
  // const progress =
  //   totalQuestions > 0
  //     ? ((currentQuestionIndex + 1) / totalQuestions) * 100
  //     : 0;
  const progress =
    totalQuestions > 0
      ? ((currentQuestionIndex + (selectedOption !== null ? 1 : 0)) /
          totalQuestions) *
        100
      : 0;
  console.log("progress", progress);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main-container">
        <div className="container progress-container">
          {/* <ProgressBar
            variant="success"
            now={40}
            style={{ backgroundColor: "green" }}
          /> */}
          {/* <ProgressBar
            now={progress}
            variant="success"
            // style={{ backgroundColor: progress > 0 ? "green" : "grey" }}
          /> */}
        </div>
        <div className="container ">
          <div className="center-layout-container">
            <div className="preview-txt">
              <p>Assessment</p>
              <p>Time Left: {formatTime(timeLeft)}</p>
            </div>
            <div className="inner-container container-fluid">
              <div className="dropdown-container">
                <div className="dropdown">
                  {/* <button
                    className="btn bg-transparent text-secondary  border dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bars "></i>
                  </button> */}
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* <a className="dropdown-item" href="#">
                      {question.duration || "Duration (minutes) :30"}
                    </a> */}
                    <a className="dropdown-item" href="#">
                      Duration (minutes):{" "}
                      {courseLink?.link6?.[0]?.duration || 30}
                    </a>
                    {/* <a className="dropdown-item" href="#">
                      {question.numberOfAttemp || "Attempt :5/0"}
                    </a> */}
                    <a className="dropdown-item" href="#">
                      Attempt: {courseLink?.link6?.[0]?.numberOfAttemp || 5}
                    </a>
                    <a className="dropdown-item" href="#">
                      Questions :{questionList.length || "2"}
                    </a>
                    <a className="dropdown-item" href="#">
                      Total Marks :{questionList.passingMarks || "10"}
                    </a>{" "}
                    <a className="dropdown-item" href="#">
                      Pass Marks :{questionList.passingMarks || "5"}
                    </a>
                  </div>
                </div>
              </div>

              <div className="inner-container-heading">
                {/* {"Untitled Chapter"} */}
              </div>
              {/* <div className="inner-container-subheading"></div> */}
            </div>
            {/* {question?.map((questiondata, index) => {
              return (
                <QuizQuestionCard
                  key={index}
                  questiondata={questionList[currentQuestionIndex]}
                  onAnswer={handleAnswer} // Pass handleAnswer to QuizQuestionCard
                />
              );
            })} */}
            {questionList.length > 0 && (
              <QuizQuestionCard
                questiondata={questionList[currentQuestionIndex]}
                selectedOption={selectedOption}
                onAnswer={handleAnswer} // Pass handleAnswer to QuizQuestionCard
                handleNextQuestion={handleNextQuestion}
              />
            )}
          </div>
          <div className="row info-submit">
            <div className="col-12 text-center">
              {selectedOption !== null && (
                <button className="submit-btn" onClick={handleNextQuestion}>
                  {currentQuestionIndex < totalQuestions - 1
                    ? "Next Question"
                    : "Completed? Submit Now."}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AssignmentQuiz;
