import { useEffect, useState } from "react";
import {
  FetchSingleCourse,
  GetUserDetails,
  getAllUserCourses,
  getProgressById,
  getSingleCourse,
  statusCheck,
} from "Axios/apis";
import { useDispatch, useSelector } from "react-redux";
import { Show } from "components/Show";
import carrer2 from "icons/carrer (2).png";
import carrer5 from "icons/career5.png";
import arrow from "icons/arrow.png";
import clock from "icons/clock.svg";
import LargeVideo from "components/assets/icon/large-video.png";
import SmallVideo from "components/assets/icon/small-video.png";
import why from "assets/why.png";
import star from "icons/star.svg";
import ug from "assets/gu.png";
import pg from "assets/pg.png";
import interview from "assets/ip.png";
import resume from "assets/resume.png";
import je from "assets/je.png";
import carrer6 from "icons/career6.png";
import stardark from "icons/stardark.svg";
import prettyName from "pretty-name";
import "./dashboard.css";
import HeaderTop from "components/HeaderTop";
import Spinner1 from "../Spinner/Spinner";
import TimelineComp from "components/TimelineComp/TimelineComp";
import FrequentlyAsked from "components/Home/FrequentlyAsked";
import FiveD from "components/assets/icon/5D.png";
import ACCURACY from "components/assets/icon/ACCURACY.png";
import REPORT from "components/assets/icon/REPORT-1.png";
import COUNSELLING from "components/assets/icon/COUNSELLING.png";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import CourseData from "components/CourseData";
import { Featurecard } from "components/Featurecard/Featurecard";

const SingleMyCourse = (props) => {
  const params = useParams();
  const [courseData, setCourseData] = useState();
  const [videoLink, setVideoLink] = useState({
    videoLink1: "",
    videoLink2: "",
  });
  const [courseid, setCourseid] = useState("");
  const navigate = useNavigate();
  const [counselling, setCounselling] = useState("");
  const [largeVideo, setVideo] = useState();
  const [reportDetails, setReportDetails] = useState({});
  const [activecourse, setActiveCourse] = useState(false);
  const [firstHead, setFirstHeaad] = useState("");
  const [secondHead, setSecondHead] = useState("");
  const [proffcontent, setProffContent] = useState("");

  const [progresscircle, setProgressCircle] = useState(0);
  const accessCode = JSON.parse(localStorage.getItem("accessCode")) || [];
  const singleAccessToken = accessCode?.filter(
    (item) => item.courseID === params.id
  );
  const removeUselessWords = (txt) => {
    var uselessWordsArray = ["with", "counselling", "without", "counseling"];

    var expStr = uselessWordsArray.join("|");
    return txt
      .replace(new RegExp("\\b(" + expStr + ")\\b", "gi"), " ")
      .replace(/\s{2,}/g, " ");
  };

  // //console.log(params);
  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const userid = localStorage.getItem("userId");
      const response = await FetchSingleCourse(params.id, userid);
      setVideoLink({
        videoLink1: response?.data?.courses?.videoLink1,
        videoLink2: response?.data?.courses?.videoLink2,
      });
      if (
        response?.data?.courses?.name?.includes("Under-Grad/Graduates") ||
        response?.data?.courses?.name?.includes("Graduates/Under-Graduates")
      ) {
        setVideo(ug);
      }
      if (response?.data?.courses?.name?.includes("Post-Graduates")) {
        setVideo(pg);
      }
      if (params.id === "6343c119e33839d145810f19") {
        setVideo(resume);
      }
      if (params.id === "6343c15fe33839d145810f31") {
        setVideo(interview);
      }
      if (params.id === "6343c195e33839d145810f48") {
        setVideo(je);
      }
      // //console.log(response?.data?.courses);
      if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("with counseling") ||
        response?.data?.courses?.name
          .toLowerCase()
          .includes("with counseling") ||
        response?.data?.courses?.name
          .toLowerCase()
          .includes("(with counseling)")
      ) {
        //console.log("Yes");
        setCounselling("with Counseling");
      } else if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("without counseling") ||
        response?.data?.courses?.name
          .toLowerCase()
          .includes("(without counseling)")
      ) {
        //console.log("No");
        setCounselling("without Counseling");
      }
      // //console.log(removeUselessWords(response?.data?.courses?.name));
      setSingleCourse(response?.data?.courses);
      if (
        response?.data?.courses?.name == "Corporate Sector Learning" ||
        response?.data?.courses?.name == "Softskill Competency Coaching" ||
        response?.data?.courses?.name ==
          "Digital Profiling & Professional Networking" ||
        response?.data?.courses?.name == "Automated Resume Builder" ||
        response?.data?.courses?.name == "Elevator Pitch & Group Discussions" ||
        response?.data?.courses?.name == "Interview Skills Training" ||
        response?.data?.courses?.name == "Job Identification & Application"
      ) {
        let newcoursedata = CourseData.filter(
          (course) => course.name == response?.data?.courses?.name
        );
        setCourseData(newcoursedata[0]);
        const fullstring = removeUselessWords(newcoursedata[0].name);
        const splitfullstring = fullstring.split(" ");
        if (splitfullstring.length === 3) {
          setActiveCourse(true);
          setloading(false);
          setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
          setSecondHead(splitfullstring[2]);
        } else if (splitfullstring.length === 5) {
          setActiveCourse(true);
          setloading(false);
          setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
          setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
        } else if (splitfullstring.length === 4) {
          setActiveCourse(true);
          setloading(false);
          setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
          setSecondHead(splitfullstring[3]);
        }
        // if (newcoursedata[0].name.toLowerCase().includes("ai-powered")) {
        //   setFirstHeaad(newcoursedata[0].name.split(" "));
        //   setAiPowered(true);
        //   setActiveCourse(true);
        // } else {
        //   setFirstHeaad(newcoursedata[0].name.split(" "));
        //   setActiveCourse(true);
        // }
        setProffContent([
          {
            heading: "Benefits of AI-Powered Viosa Career Roadmap",
            para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
            para2:
              "Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.",
          },
          {
            heading: "Why AI-Powered Career Assessment & Planning?",
            para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5 dimensions of your professional personality.",
            para2:
              "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
          },
        ]);
        // //console.log(newcoursedata[0].name.split(" "));
      } else {
        setCourseData({
          isCounselling: response?.data?.courses?.isCounselling,
          name: removeUselessWords(response?.data?.courses?.name),
          desc:
            response?.data?.courses?.description ||
            response?.data?.courses?.name,
          // "The difference between User Interface (UI) and User Experience (UX) is that UI refers to the aesthetic elements by which people interact with a product, while UX is about the experience a user has with a product or service",
          time: "60 min",
          price: response?.data?.courses?.price || "999",
          text1: "You Will Learn & Gain Expertise on Various Sectors",
          text2: "Identify the Industry & Sector Of Your Interest",
          text3: "Course duration: 240 minutes (2 sessions)",
          videoLink1: response?.data?.courses?.videoLink1,
          videoLink2: response?.data?.courses?.videoLink2,
          text4: [
            "- Recorded Sessions",
            "- Learn Sector-Based Competencies",
            "- 240 min modules ",
            "- 30 days access for a revision session",
          ],
          text5: [
            {
              head: "Benefits Of AI-Powered Viosa Career Roadmap",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding.",
              para2:
                "Choosing the right professional career is one of the most important decisions in everyone’s life.It is imperative to know what kind of work environment suits you the best.All these important parameters can be identified through AI-Powered professional career assessment and a professional counsellor.",
            },
            {
              head: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5 dimensions of your professional personality. ",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ],
          featurecarddata: [
            {
              icon: FiveD,
              heading: "5 Dimensional Professional Personality Analysis",
              subheading:
                "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
            },
            {
              icon: ACCURACY,
              heading: "98.6 % Accuracy",
              subheading:
                "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
            },
            {
              icon: REPORT,
              heading: "24+ Page Detailed Professional Career Plan ",
              subheading:
                "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
            },
            {
              icon: COUNSELLING,
              heading:
                "Career Assessment & Counseling In the Language Of Your Choice",
              subheading:
                "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
            },
          ],
          timelinedata: [
            "Start Career Assessment",
            "Get a 24+ Page Report",
            "Analyse Your Career Paths",
            "Get Personalised Career Counseling",
          ],
          text6: `How will ${response?.data?.courses?.name} help you?`,
          text7: [
            {
              iconImg: carrer2,
              head: "Sector Knowledge",
              para: "Learn about sector and respective competencies to achieve your aspirational professional roles",
            },
            {
              iconImg: carrer5,
              head: "Functional Understanding",
              para: "Employees who possess frontend/backend skills tend to be more successful in professional careers at an early stage",
            },
            {
              iconImg: carrer6,
              head: "Industry Trends",
              para: "Learn about latest trends in your choice of industry and government initiative for top 5 growing sectors",
            },
          ],
          faq: [
            {
              headQuestion: "What is five dimensional career assessment?",
              paraAnswer: `Our career assessment explores the 5 dimensions of the candidate. It
              includes Personality analysis, Career Interest analysis, Career
              motivators analysis, learning style analysis and Skills & Abilities
              analysis.`,
            },
            {
              headQuestion:
                "What is the difference between Free Suitability and Comprehensive Analysis?",
              paraAnswer: `Both are 5 dimensional career assessment. In Free Suitability,
              candidate can get analysis of one career path of their choice with
              free 3 pages basic report. In Comprehensive plan, career analysis will
              be done across 160+ career paths and 2000+ Occupations to find out the
              best career path for you. Comprehensive 28+ pages career report with
              execution plan will help you to achieve career goals.`,
            },
            {
              headQuestion:
                "Once Comprehensive report is activated, can I see analysis other career paths as well?",
              paraAnswer: `Yes, very much. This is the biggest advantage every student is having.
              Once assessment is done student can generate unlimited Comprehensive
              28+ pages Comprehensive reports of different career paths. It is
              important to know you most preferred career paths but equally it is
              important to know about career paths that you should avoid or develop.`,
            },
            {
              headQuestion:
                "Do I need to give assessment once again for Comprehensive analysis?",
              paraAnswer: `No, you do not need to give assessment again. Once assessment is done,
              you can upgrade any time. System will automatically analyze all career
              paths and generate Comprehensive report. Our powerful Artificial
              intelligence engine will unlock all the career path analysis.`,
            },
          ],
        });
      }
      setloading(false);
    };
    const setSpecificCourses = () => {
      setCourseid(params.id);
      fetchCourse();
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSpecificCourses();
  }, [params.id]);

  const [courses, setCourses] = useState([]);
  const [loading, setloading] = useState(true);
  const [singleCourse, setSingleCourse] = useState([]);
  const token = localStorage.getItem("token");
  const InterviewProRole = localStorage.getItem("InterviewProRole");
  const [lastCourse, setLastCourse] = useState([]);
  useEffect(() => {
    const fetchCourse = async () => {
      const response = await getAllUserCourses(token);
      setCourses(response?.data?.courses);
    };
    fetchCourse();
  }, []);
  const { course } = useSelector((state) => state.course);
  const [isOpened, setIsOpened] = useState(true);
  const [isReportReady, setIsReportReady] = useState(false);

  const [show, setShow] = useState(false);

  const [progress, setProgress] = useState(0);
  const [totalLive, setTotalLive] = useState(0);
  const [totalChapter, SettotalChapter] = useState(0);
  const [totalCompletedCount, setTotalCompletedCount] = useState(0);
  const [countotalsum, settotalsum] = useState(0);
  const [courseprogress, setCourseProgress] = useState({});
  useEffect(() => {
    if (courses.length) {
      const totalLive1 = courses?.map((item) =>
        setTotalLive((prev) => prev + item.liveSession.length)
      );
      const totalChapter = courses?.map((item) =>
        item.section.reduce((total, section) => {
          SettotalChapter((prev) => prev + section.chapter.length);
        }, 0)
      );
      const count2 = courses
        ?.map((item) => {
          return item?.section?.map(
            (item) =>
              item.chapter.filter((value) => value.isCompleted === true).length
          );
        })
        .flat();
      // //console.log(count2);

      const sumofAllChapter = count2
        ?.flat()
        ?.reduce((partialSum, a) => partialSum + a, 0);
      settotalsum(sumofAllChapter);
    }
  }, [courses]);
  const userID = localStorage.getItem("userId");

  useEffect(() => {
    if (userID) {
      const fetch = async () => {
        const user = await GetUserDetails(userID);
        const cdfind = user?.data?.result[0]?.cd?.filter((i) => {
          if (i?.split("_")[1] === params.id) {
            return i;
          }
        });
        const removeUndefined = cdfind?.filter((i) => i !== undefined);
        const fetchStatus = await statusCheck({
          cd: removeUndefined[0]?.split("_")[0],
          access_code: "abaven_" + user.data.result[0]?.serialNumber,
        });
        if (fetchStatus?.data?.status.status === 3) {
          setReportDetails({
            access_code: "abaven_" + user.data.result[0]?.serialNumber,
            cd: removeUndefined[0]?.split("_")[0],
          });
          setIsReportReady(true);
        }
      };
      fetch();
    }
  }, [userID]);

  useEffect(() => {
    if (totalChapter > 0) {
      setTotalCompletedCount((countotalsum / totalChapter) * 100);
    }
  }, [totalChapter]);

  useEffect(() => {
    if (lastCourse.length) {
      const updatedProgress =
        (courseprogress.completedChapters / courseprogress.totalChapters) * 100;
      // //console.log(updatedProgress)
      setProgress(updatedProgress);
    }
  }, [lastCourse]);
  // useEffect(() => {
  //     if (lastCourse.length) {
  //         const updatedProgress = (sum / totalChapters) * 100;
  //         setProgress(updatedProgress);
  //     }
  // }, [lastCourse]);

  // //console.log(progressLine)
  const totalChapter2 = courses.map((item) =>
    item.section.reduce((total, section) => {
      return total + section.chapter.length;
    }, 0)
  );

  const totalSections = lastCourse.map((item) => item.section.length)[0];
  const progressLine = Math.ceil(progress / (100 / totalSections)) || 0;
  const [progressArray, setProgressArray] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const res = await getSingleCourse(
        {
          course_id: params.id,
        },
        token
      );
      // //console.log(res);
      setLastCourse([res.data.course]);
      setloading(false);
    };
    const fetchProgress = async () => {
      const resProgress = await getProgressById(
        {
          course_id: params.id,
        },
        token
      );
      // //console.log(resProgress.data.result)
      setProgressCircle(resProgress.data.result);
      setCourseProgress(resProgress.data.result);
    };
    fetchCourse();
    fetchProgress();
  }, []);
  const lastCourseProgreess =
    (progresscircle.completedChapters / progresscircle.totalChapters) * 100 ||
    0.0;

  const getAllProgressArray = () => {
    const progressArray = [];
    while (progressArray.length < progressLine) {
      const i = progressArray.length;
      progressArray.push(i);
    }
    setProgressArray(progressArray);
  };
  useEffect(() => {
    if (progressLine) {
      getAllProgressArray();
    }
  }, [progressLine]);

  const timelinedata = [
    "Start Career Assessment",
    "Get a 24+ Page Report",
    "Analyse Your Career Paths",
    "Get Personalised Career Counseling",
  ];
  console.log(courseData);
  console.log(token);

  return (
    <>
      <div className="containter mx-2">
        <HeaderTop name="My Course" togglesidebar={props.togglesidebar} />
        {loading ? (
          <Spinner1 className="m-auto" />
        ) : (
          <>
            <Show condition={isOpened && !show}>
              <div className="col-12">
                <div className="d-flex align-items-center my-3">
                  <h1 className="px-1 heading-primary" onClick={() => {}}>
                    <Link style={{ color: "#535353" }} to="/profile/dashboard">
                      All Courses
                    </Link>
                  </h1>
                  <img className="img-small" src={arrow} height="16" />
                  <h1
                    className="px-1 heading-primary"
                    style={{ color: "rgb(255 163 3)" }}
                    onClick={() => {
                      navigate(`/profile/dashboard/${params.id}}`);
                    }}
                  >
                    {singleCourse?.name}
                  </h1>
                </div>
              </div>
              <div className="bg-white container-fluid aboutus-main-container">
                <div className="align-responsive">
                  <div className="items-center onetp-res justify-between">
                    <div
                      style={{ maxWidth: "1680px" }}
                      className="hero-content flex-col lg:flex-row-reverse my-5 "
                    >
                      <div className="col-4 w-100-768">
                        <div>
                          <div className="ml-1 mt-3 col-4 box-progress gap-y-4">
                            {lastCourseProgreess !== 0 && (
                              <>
                                <div className="wrap-circles">
                                  <div
                                    className="circle per-25"
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      backgroundImage: `conic-gradient(#FEA302 ${lastCourseProgreess}%, #FFCDB2 0)`,
                                      zIndex: "9",
                                    }}
                                  >
                                    <div className="inner">
                                      {Math.ceil(lastCourseProgreess) + "%" ||
                                        0}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <p
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                fontSize: "28px",
                              }}
                              className="progress-heading"
                            >
                              Progress
                            </p>
                            <div
                              className="my-2"
                              style={{ width: "100%", padding: "0px 20px" }}
                            >
                              <div className="flex items-center justify-content-center">
                                {[...Array(totalSections)].map(
                                  (item, index) => (
                                    <>
                                      <p
                                        className={`flex justify-content-center ${
                                          progressArray?.includes(index)
                                            ? "background"
                                            : "different-background"
                                        }  text-color`}
                                      >
                                        <span
                                          style={{ color: "white" }}
                                          className="marginTop"
                                        >
                                          {index + 1}
                                        </span>
                                      </p>
                                      {index !== totalSections - 1 && (
                                        <span className="progress-line"></span>
                                      )}
                                    </>
                                  )
                                )}
                              </div>
                              <br />
                              <div className="d-flex justify-content-center align-items-center">
                                {singleAccessToken[0]?.code && "Access Code :"}
                                <span
                                  style={{
                                    border: singleAccessToken[0]?.code
                                      ? "3px dashed #FEA302"
                                      : "none",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {singleAccessToken[0]?.code}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 w-100-768">
                        <div className="">
                          <div className="flex course-head-sec items-center flex-direction-column align-item-start gap-x-4">
                            {!activecourse ? (
                              <h1
                                style={{ color: "#521986 !important" }}
                                className="course-mainheading text-start "
                              >
                                {courseData?.name.replace("( )", "")}
                                <span
                                  style={{ color: "#fea302", display: "block" }}
                                >
                                  {counselling}
                                </span>
                              </h1>
                            ) : (
                              <>
                                {(courseData?.name
                                  .toLowerCase()
                                  .includes("resume") ||
                                  courseData?.name
                                    .toLowerCase()
                                    .includes("interview") ||
                                  courseData?.name
                                    .toLowerCase()
                                    .includes("identification")) && (
                                  <p
                                    style={{
                                      display: "block",
                                      color: "black",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      fontFamily: "'Inter', sans-serif",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {" " + "AI-Powered"}
                                  </p>
                                )}
                                <h1
                                  style={{ color: "#521986 !important" }}
                                  className="course-mainheading"
                                >
                                  {courseData?.name.toLowerCase().includes("&")
                                    ? firstHead.toUpperCase() + " & "
                                    : firstHead.toUpperCase()}
                                  <span style={{ color: "#fea302" }}>
                                    {firstHead[1] !== "AI-Powered" &&
                                      " " + secondHead.toUpperCase()}
                                  </span>
                                </h1>
                              </>
                            )}
                          </div>
                          <div>
                            <div className="flex items-center mb-2 mt-2 gap-x-2">
                              <p
                                className="text-xl"
                                style={{ color: "#FFA200" }}
                              >
                                4.0
                              </p>
                              <img
                                src={star}
                                style={{ width: "15px" }}
                                alt=""
                              />
                              <img
                                src={star}
                                style={{ width: "15px" }}
                                alt=""
                              />
                              <img
                                src={star}
                                style={{ width: "15px" }}
                                alt=""
                              />
                              <img
                                src={star}
                                style={{ width: "15px" }}
                                alt=""
                              />
                              <img
                                src={stardark}
                                style={{ width: "15px" }}
                                alt=""
                              />
                            </div>
                            <p
                              style={{ width: "90%" }}
                              className="course-paragraph"
                            >
                              {courseData?.desc}
                            </p>
                          </div>

                          <button
                            className="flex items-center
                        cursor-none
                        justify-center mt-4 minit120-button gap-x-2"
                            style={{ cursor: "default" }}
                          >
                            <img src={clock} alt="" />
                            <p className="text-white">{courseData?.time} </p>
                          </button>
                          <button className="mt-5 cursor-pointer btn-primary-1 width-mob w-50">
                            {courseData?.name ===
                            "Interview Skills Training" ? (
                              <a
                                href={
                                  InterviewProRole === "student"
                                    ? `https://interviewpro.viosa.in/college-student/practice-interview/${token}`
                                    : `https://interviewpro.viosa.in/interviewee/dashboard/${token}`
                                }
                              >
                                {progressLine
                                  ? "Resume Course"
                                  : "Start Course"}
                              </a>
                            ) : (
                              <>
                                {courseData?.name ===
                                "Automated Resume Builder" ? (
                                  <>
                                    <a
                                      href={`https://resumatic.viosa.in/auth?token=${localStorage.getItem(
                                        "token"
                                      )}&id=${localStorage.getItem("userId")}`}
                                    >
                                      {progressLine
                                        ? "Resume Course"
                                        : "Start Course"}
                                    </a>
                                  </>
                                ) : (
                                  <span onClick={() => navigate(`curriculum`)}>
                                    {progressLine
                                      ? "Resume Course"
                                      : "Start Course"}
                                  </span>
                                )}
                              </>
                            )}
                          </button>
                          {isReportReady && (
                            <button
                              className="mt-5 cursor-pointer btn-primary-1 mx-2"
                              style={{ width: "40%" }}
                              onClick={() =>
                                window.open(
                                  `https://pca.viosa.com/access-login-api3.php?access_code=${reportDetails?.access_code}&cd=${reportDetails?.cd}&clusters=0&directreport`,
                                  "_blank"
                                )
                              }
                            >
                              {progressLine ? "Resume Course" : "View Report"}
                            </button>
                          )}
                          <p className="course-month-head">
                            {courseData?.timeMonth}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ maxWidth: "1680px", position: "relative" }}
                      className="py-10 row roadmap-section-container"
                    >
                      <div className="col-7 w-100-768 roadmap-card-container">
                        {courseData?.featurecarddata.map((carddata, idx) => {
                          return (
                            <Featurecard
                              key={idx}
                              heading={carddata?.heading}
                              // subheading={carddata.subheading}
                              icon={carddata?.icon}
                            />
                          );
                        })}
                      </div>
                      <div
                        className="col-5 w-100-768 roadmap-feature-containers"
                        style={{ position: "relative" }}
                      >
                        {singleCourse?.order < 5 ? (
                          <div className="d-flex justify-end price-box-res">
                            <video
                              className="roadmap-feature-video"
                              loop
                              controls
                              poster={why}
                              controlsList="nodownload"
                              src={videoLink?.videoLink1}
                            ></video>
                          </div>
                        ) : (
                          <div className="d-flex justify-end price-box-res">
                            <img
                              src={videoLink?.videoLink1}
                              width="100%"
                              lazy="loading"
                              alt="video"
                              height="400px"
                              style={{ position: "absolute", top: "-70px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ maxWidth: "1680px" }}
                    className="hero-content flex-col lg:flex-row-reverse m-auto"
                  >
                    <div className="col-12 ">
                      {courseData?.text5.map((data, idx) => {
                        return (
                          <>
                            <br />
                            {data.head && (
                              <p className="schools-subheading  text-start ">
                                {data.head}
                              </p>
                            )}
                            {data.para && (
                              <>
                                <p
                                  key={idx}
                                  className="course-paragraph text-justify"
                                >
                                  {data.para}{" "}
                                </p>{" "}
                                <br />
                              </>
                            )}
                            {data.para2 && (
                              <p
                                key={idx}
                                className="course-paragraph text-justify"
                              >
                                {data.para2}
                              </p>
                            )}
                            {data?.list?.length !== 0 && (
                              <>
                                <ul className="list-experts">
                                  {data?.list?.map((e) => (
                                    <li className="course-paragraph text-justify">
                                      {e}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <section className="py-5 row roadmap-section-container">
                    <TimelineComp data={timelinedata} />
                  </section>
                  <div
                    style={{ maxWidth: "1680px" }}
                    className="mb-12 hero-content flex-col lg:flex-row-reverse"
                  ></div>
                </div>
                {!firstHead.includes("Corporate") &&
                !firstHead.includes("Competency") &&
                !firstHead.includes("Elevator") &&
                !firstHead.includes("Digital") ? (
                  <section className="py-10 row roadmap-section-container">
                    <FrequentlyAsked faq={courseData?.faq} />
                  </section>
                ) : (
                  <></>
                )}
              </div>
            </Show>
          </>
        )}
      </div>
    </>
  );
};

export default SingleMyCourse;
