import React from "react";
import "./AssignmentSubmit.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
const AssignmentSubmit = () => {
  // const navigate = useNavigate();
  // function handleClick() {
  //   navigate("/assignment/start");
  // }
  const location = useLocation();
  const { userAnswers, questionList } = location.state || {};
  const navigate = useNavigate(); // Using useNavigate instead of useHistory

  const handleGoBack = () => {
    // Go back to the previous page twice
    navigate(-2); // You can also use navigate(-1) to go back once
  };
  const calculateResults = () => {
    let correctAnswers = 0;
    let incorrectAnswers = 0;
    if (Array.isArray(userAnswers)) {
      // Check if userAnswers is an array
      userAnswers.forEach((answer) => {
        const question = questionList.find((q) => q.id === answer.questionId);
        console.log("questionOption", question.correctAnswer);
        console.log("question", question);
        console.log("selectOption", answer.selectedOption);
        console.log("answer", answer);
        if (question && question.correctAnswer === answer.selectedOption) {
          // Ensure question exists before accessing correctOption
          correctAnswers += 1;
        } else {
          incorrectAnswers += 1;
        }
      });
    } else {
      console.log("User answers are not available or are not an array.");
    }
    return { correctAnswers, incorrectAnswers };
  };

  const results = calculateResults();
  console.log("results", results);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main-container">
        <div className="outer-container">
          <div className="container ">
            <div className="center-layout-container">
              <div className="preview-txt">
                <p>Result</p>
              </div>
              <div className="inner-container container-fluid">
                <div className="inner-container-heading">Assessment</div>
                <div className="inner-container-subheading">
                  {" "}
                  Thank you for submitting your answers.
                </div>
                {/* <div className="result-summary">
                  <p>Correct Answers: {results.correctAnswers}</p>
                  <p>Incorrect Answers: {results.incorrectAnswers}</p>
                </div> */}
              </div>
              {/* <Link to="/course/${courseId}">Go to Course</Link> */}
              <button onClick={handleGoBack}>Go to Course</button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AssignmentSubmit;
