import axios from "axios";
import Cookies from "js-cookie";
// import cookies from 'js-cookies';
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const API2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_INTERVIEWPRO,
});
const API3 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_RESUMATIC,
});
const API4 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_PCA,
  // baseURL: "https://pca.viosa.in",
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
API2.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API2.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers["token"] = localStorage.getItem("token");
  }
  return req;
});
API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers["token"] = localStorage.getItem("token");
  }
  return req;
});
API3.interceptors.request.use((req) => {
  if (Cookies.get("token")) {
    req.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
  }
  return req;
});
// API.interceptors.request.use((req) => {
//   if (cookies.get("viosa")) {
//     req.headers["x-access-token"] = cookies.get("viosa");
//   }
//   return req;
// });

const headers = {
  "Content-Type": "application/json",
  token: `${localStorage.getItem("token")}`,
};

export const getIntervieweeData = () =>
  API2.get("/auth/interviewee/dashboard", { headers: headers });
export const getStudentData = () =>
  API2.get("/auth/student/dashboard", { headers: headers });

export const Signin = (formData) => API.post("/user/auth/login", formData);
export const SignUpApi = (formData) => API.post("user/auth/signup", formData);
export const pca = (formData, token, id) =>
  API.post(`user/pca?course_id=${id}`, formData, {
    headers: {
      token: token,
    },
  });
export const SignUp = (formData) => API.post("/auth/user/signup", formData);
export const SignUpByGoogle = (formData) =>
  API.post("/user/auth/sociallogin", formData);
export const SignInByGoogle = (formData) =>
  API.post("/user/auth/sociallogin", formData);

export const forgotPassword = (formData) =>
  API.post("/auth/forgot_password", formData);
export const resetPasswordApi = (formData, id) =>
  API.post(`/auth/resetPassword/${id}`, formData);
export const Payment = (formData) =>
  API.post("/payment/buy_course_request", formData);
export const ApplyCoupon = (formData) =>
  API.post("/user/apply-coupon", formData);
export const AddEducation = (formData) => API.post("/user/education", formData);
export const AddWork = (formData) => API.post("user/work-exprience", formData);
export const ChangePasswordApi = (formData, token) =>
  API.post("/user/auth/change-password", formData, {
    headers: {
      token: token,
    },
  });

// create teams

export const createTeam = (formData) =>
  API2.post("/auth/college/createteam", formData);

export const GetTeam = () => API2.get("/auth/college/teams");
export const GetTeamsbyCollegeId = (id) =>
  API2.get(`/auth/college/teams/college/${id}`);
export const GetBatch = () => API2.get("/auth/college/batches");

export const GetStudentByCollegeId = () =>
  API2.get("/auth/college/getallstudent");
export const GetStudentByBatchId = (id) => API2.get(`/auth/college/team/${id}`);
export const GetCourseByBatchId = (id) => API2.get(`/auth/college/teams/${id}`);
export const AddStudentToBatch = (payload) =>
  API2.post(`/auth/college/addstudenttobatch`, payload);

export const RemoveStudentFromBatch = (payload) =>
  API2.post(`/auth/college/removestudentfrombatch`, payload);

export const EditProfile = (formData) => API.patch("user/profile", formData);

export const updateCourse = (formData, token) =>
  API.patch("/user/course/play/chapter", formData, {
    headers: {
      token: token,
    },
  });
export const getChapter = (formData, token) =>
  API.post("/user/getChapter", formData, {
    headers: {
      token: token,
    },
  });

export const getProgress = (data, token) =>
  API.post("user/progress/all", data, {
    headers: {
      token: token,
    },
  });

export const getBarGraph = () =>
  API.get(`user/aptitude/graph`, { headers: headers });

export const PaymentVerify = (formData) =>
  API.post("/payment/buy_course_request", formData);
export const getAllCourseWithPrice = (data) =>
  API.post("/admin/course/all", data);
export const getAllCourse = (data) => API.get("/admin/course");
export const getMyCourse = (data) => API.post("/user/course/mycourse", data);
export const getAllUserCourses = (token) =>
  API.get("/user/course", {
    headers: {
      token: token,
    },
  });
export const getAllUserCourses2 = (token) =>
  API.get("/user/course/courses", {
    headers: {
      token: token,
    },
  });
export const getCoursebyId = (formData) =>
  API.post("/course/get/course", formData);
export const getCourseDetailsbyId = (id, userid) =>
  API.get(`user/courseById/?course_id=${id}`, {
    headers: { accessid: userid },
  });
export const getCourseDetailsbySlug = (slug, userid) =>
  API.get(`user/courseBySlug/?slug=${slug}`, {
    headers: { accessid: userid },
  });
export const FetchSingleCourse = (id, userid) =>
  API.get(`user/courseById/?course_id=${id}`, {
    headers: { accessid: userid },
  });

export const getSingleCourse = (data, token) =>
  API.post("/user/singleCourse", data, {
    headers: {
      token: token,
    },
  });

export const getProgressById = (data, token) =>
  API.post("/user/progress/courseById", data, {
    headers: {
      token: token,
    },
  });
export const FetchCourse = () => API.get(`/course`, {});

export const GetUserDetails = (userID) => API.get(`/user/${userID}`);

// user cart

export const AddToCart = (cart) => API.post("/user/cart", cart);
export const UpdateCartItem = (data) => API.patch("/user/cart", data);
export const clearCart = (data) => API.patch("/user/clear-cart", data);
export const DeleteCartItem = (cart) => API.post("/user/cart/delete", cart);

// contact form

export const CareerCounselorForm = (data) =>
  API.post("/user/carrerCounseller", data);
export const CollabForm = (data) => API.post("/user/collab", data);
export const SupportForm = (data) => API.post("/user/support", data);
export const SupportForm2 = (data) => API.post("/user/sendenquiry", data);

// status check pca
export const statusCheck = (data) => API.post("/user/pca-status", data);

export const getCollegeDashboardApi = (token) =>
  API.get("/college/dashboard?page=1&limit=20", {
    headers: {
      token: token,
    },
  });
export const getCollegeResumeApi = (page = 1, search = "", token) =>
  API.get(
    `/college/studentWhoCreatedResume?page=${page}&limit=20&search=${search}`,
    {
      headers: {
        token: token,
      },
    }
  );
export const getCollegeInterviewApi = (page = 1, search = "", token) =>
  API.get(
    `/college/studentWhoGivenInterview?page=${page}&limit=20&search=${search}`,
    {
      headers: {
        token: token,
      },
    }
  );
export const GetTrailResume = () => API3.get(`/resume/trail/`);
export const GetTrailResumeById = (id, token) =>
  API3.get(`/resume/trail/${id}`, {
    headers: {
      token: token,
    },
  });
export const ChatGPTAPI = (AiCredits, data) =>
  AiCredits > 0 ? axios.post(`https://chatgpt.viosa.in/chat`, data) : false;
export const GetAiResumeById = (id) => API3.get(`/resume/ai/${id}`);
export const GetAutomatedResumeById = (id) =>
  API3.get(`/resume/automated/${id}`);
export const getProfile = (id) => API3.get(`/user/getProfile/${id}`);
export const StudentResumes = () => API3.post(`/studentResumes`);
export const GetBatchesFromCollegeId = (id) =>
  API2.get(`/auth/college/teams/college/${id}`);
export const AssignInterview = (formData) =>
  API2.post("/interview/assign-interview", formData);

export const PcaStatus = (accessCode) =>
  API.get(`/api/proxy?access_code=${accessCode}`);

export const googlesheet = (formData) => API.post(`/api/submit-form`, formData);
export const batchbyId = (id) =>
  API2.get(`/auth/college/getBatchnamebyBatchid/${id}`, id);

export const collegebyId = (id) =>
  API2.get(`/auth/college/getCollegebyCollegeid/${id}`, id);

export const fetchAllColleges = () => API2.get(`/auth/college/all`);
export const StudentSignUp = (formData) =>
  API2.post("/auth/student/signup", formData);
export const UpdateTeam = (formData) =>
  API2.post("/auth/college/updateteam", formData);
export const AddCoursefieldsintouser = (formData) =>
  API2.post("/auth/student/courseToUser", formData);
